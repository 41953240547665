import React from "react"
import Layout from "../layouts"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { themeGet } from 'styled-system'
import { Container } from '../components/Panel'

import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
`


const ImageFadeIn = keyframes`
	0% {
		transform: translate(0, 100px);
		opacity:0;
	}
	100% {
		transform: translate(0, 0);
		opacity:1;
	}
`		

const Image = styled(Img)`
	width:70%;
    margin-left: -30%;
    box-shadow: 34px 34px 0px #CCB698;
    opacity: 0;
	animation: ${ImageFadeIn} ease 1s;
	
	animation-fill-mode: forwards;

    & div {
    	border: 1px solid;	
    }

	@media (max-width: ${themeGet('breakpoints.6')}) {
    	width: 50%;
    	height: auto;
		margin-left: calc((50% - 50vw)/3);
	}
	@media (max-width: ${themeGet('breakpoints.4')}) {
    	width: 45%;
    }
    @media (max-width: ${themeGet('breakpoints.3')}) {
		margin: 1em 0;
		box-shadow: 30px 30px 0px #CCB698;
	}
	@media (max-width: ${themeGet('breakpoints.2')}) {
		box-shadow: 25px 25px 0px #CCB698;
	}
	@media (max-width: ${themeGet('breakpoints.1')}) {
		box-shadow: 20px 20px 0px #CCB698;
		width: 95%;	
	}
`

const Text = styled.div`
	color: ${themeGet('colors.contrast.4')};
	position: absolute;
    top: 400px;
    left: 50%;
    width: 50%;
    max-width: 560px;
    opacity: 0;
	animation: ${fadeIn} ease 1s;
	animation-delay: 1s;
	animation-fill-mode: forwards; 

    & h1 {
    	font-size: ${themeGet('headings.9')};
    	margin-left: -40%;
    	margin-bottom: 0px;
    }

    & h3 {
    	font-weight: normal;
    	font-size: ${themeGet('fontSizes.7')};
    }

    & p {
    	font-size: ${themeGet('fontSizes.5')};
    }

    @media (max-width: ${themeGet('breakpoints.6')}) {
    	top: 200px;
    	left: 48%;
    	width: 52%;
    	& h1 {
			font-size: ${themeGet('headings.8')};
			margin-left: -35%;
		}
		& h3 {
	    	font-size: ${themeGet('fontSizes.6')};
	    }
	    & p {
	    	font-size: ${themeGet('fontSizes.4')};
	    }
    }

    @media (max-width: ${themeGet('breakpoints.4')}) {
    	top: 150px;
    	left: 50%;
    	width: 50%;
    	& h1 {
			font-size: ${themeGet('headings.8')};
			margin-left: -35%;
		}
    }

    @media (max-width: ${themeGet('breakpoints.3')}) {
    	top: 250px;
    	& h1 {
    		margin-left: -25%;
			font-size: ${themeGet('headings.7')};
		}
		& h3 {
			font-size: ${themeGet('fontSizes.6')};
		}
    }

    @media (max-width: ${themeGet('breakpoints.1')}) {
    	position: relative;
		top: 0;
    	left: 0;
    	width: 100%;
    	& h1 {
			margin-left: 0;
			font-size: ${themeGet('headings.6')};
			text-align: center;
		}
		& h3 {
			font-size: ${themeGet('fontSizes.5')};
		}
	}
`

const Content = Container.extend`
	min-height: calc(100vh - 158px);
	padding: 7.5em 0;
	
	@media (max-width: ${themeGet('breakpoints.6')}) {
    	padding: 5em 0;
	}
	@media (max-width: ${themeGet('breakpoints.5')}) {
    	padding: 0;
	}
	@media (max-width: ${themeGet('breakpoints.3')}) {
    	padding: 5em 0;
	}
	@media (max-width: ${themeGet('breakpoints.0')}) {
    	padding: 0;
	}
`
const AboutPage = ({data}) => (
	<Layout>
		<Content>
			<Image fluid={data.file.childImageSharp.fluid} />
			<Text>
				<h1>About Me</h1>
				<h3>I’m an entrepreneur focused on artificial intelligence, robotics, health care and the life sciences.</h3>
				<p>I’m the founder, owner and CEO of Skyborn, a holistic premium life style brand with a scientific approach to beauty, health and good living. I’m a former Dutch parliamentarian who created Door Networks a European media company with content made by the youth for the youth. I also build and develop services like Advocaatbot and Testamentbot. I'm on a mission to help people live better through understanding themselves and the world around them.</p>
			</Text>
		</Content>
	</Layout>
)

export default AboutPage

export const query = graphql`
	query {
	  file(relativePath: {eq: "about/profile_picN.jpg"}) {
	    childImageSharp {
	       	fluid(maxWidth: 500) {
		      ...GatsbyImageSharpFluid
		    }
	    }
	  }
	}
`
