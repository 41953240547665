import styled from 'styled-components'
import { themeGet } from 'styled-system'
import Box from '../Box'
import Text from '../Text'
import Wrapper from '../Wrapper'

const fullCover = `
	bottom: 0;
	left: 0;
	position: absolute !important;
	right: 0;
	top: 0;
`

export const Cover = styled(Box)`
	@media (min-width: ${themeGet('breakpoints.1')}) {
		.gatsby-image-outer-wrapper,
		.gatsby-image-wrapper {
			${fullCover}
		}
	}
`

Cover.defaultProps = {
	display: 'flex',
	flexDirection: 'column',
	height: ['auto', 'auto', '100%'],
	mb: [4, 4, 0],
	position: ['relative', 'relative', 'absolute'],
	width: ['100vw', '100vw', '100%']
}

export const InlineImage = Box.extend`
	& > .gatsby-image-outer-wrapper {
		align-items: center;
		display: flex;
		flex: 1;
	}

	img:nth-child(3) {
		position: relative !important;
	}
`

InlineImage.defaultProps = {
	alignItems: 'center',
	display: 'flex',
	flex: '1',
	position: 'relative'
}

export const Copy = styled(Wrapper)``

Copy.defaultProps = {
	display: 'flex',
	flexDirection: 'column',
	maxWidth: 'narrow',
	position: 'relative'
}

export const Heading = Text.extend``.withComponent('h3')

Heading.defaultProps = {
  	fontSize: 9,
	fontWeight: 'bold',
	lineHeight: 1,
	mb: 2,
	mt: 0,
	width: ['100%', '100%', '50%']
}

export const Description = Text.extend``.withComponent('p')

Description.defaultProps = {
	fontSize: 4,
	maxWidth: '27em',
	m: 0,
	width: ['100%', '100%', '50%']
}

export const Container = Box.extend``

Container.defaultProps = {
  display: 'flex',
  flexDirection: 'column'
}

const Panel = Box.extend``

Panel.defaultProps = {
	alignItems: 'center',
	display: 'flex',
	flexDirection: ['column', 'column', 'row'],
	height: 'auto',
	minHeight: ['auto', 'auto', '36em'],
	justifyContent: 'center',
	overflow: 'hidden',
	position: 'relative',
	py: 5,
	width: '100%'
}

export default Panel
